import { JOURNEY_QUERY_PARAM } from '@/lib/variables';

import URL_ENUM from '@/lib/types/enums/urls.enum';

export const useQuoteLink = () => {
  const route = useRoute();

  return computed(() => {
    if (route.params.typeorwishorjourneyslug || route.params.journeyslug) {
      return `/${URL_ENUM.QUOTE}?${JOURNEY_QUERY_PARAM}=${route.params.typeorwishorjourneyslug || route.params.journeyslug}`;
    }

    if (
      route.params.geographiczoneslug &&
      route.params.countryslug &&
      route.params.stopovertownortypeorwishslug
    ) {
      return `/${URL_ENUM.QUOTE}?destination=${route.params.geographiczoneslug}/${route.params.countryslug}/${route.params.stopovertownortypeorwishslug}`;
    }

    if (route.params.geographiczoneslug && route.params.countryslug) {
      return `/${URL_ENUM.QUOTE}?destination=${route.params.geographiczoneslug}/${route.params.countryslug}`;
    }

    return `/${URL_ENUM.QUOTE}`;
  });
};
